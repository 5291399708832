<template>
  <b-card>
    <b-overlay rounded="sm" :show="isLoading" no-wrap />

    <!-- form -->
    <validation-observer ref="profileLocation" tag="form">
      <b-row>
        <b-col md="6">
          <b-form-group label="Страна" label-for="country">
            <validation-provider
              #default="{ errors }"
              name="Страна"
              rules="required"
            >
              <wayup-select
                v-model="selectedCountry"
                placeholder="Выберите страну"
                empty-filter-message="Страны не найдены"
                :options="countries"
                label="name"
                :state="errors.length > 0 ? false : null"
                :clearable="false"
                @change="onCountryChanged"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Город" label-for="city">
            <validation-provider
              #default="{ errors }"
              name="Город"
              rules="required"
            >
              <wayup-select
                v-model="selectedCity"
                placeholder="Выберите город"
                :disabled="form.country === null"
                empty-filter-message="Города не найдены"
                :options="cities"
                label="name"
                :pending="citiesPending"
                :state="errors.length > 0 ? false : null"
                :clearable="false"
                @change="onCityChanged"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col sm="12">
          <b-form-group label="Улица" label-for="street">
            <validation-provider
              #default="{ errors }"
              name="Улица"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="HomeIcon" />
                </b-input-group-prepend>

                <b-form-input
                  id="street"
                  v-model="form.street"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder="(Не указано)"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col sm="4">
          <b-form-group label="Дом" label-for="house">
            <validation-provider
              #default="{ errors }"
              name="Дом"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="HomeIcon" />
                </b-input-group-prepend>

                <b-form-input
                  id="house"
                  v-model="form.house"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder="(Не указано)"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col sm="4">
          <b-form-group label="Строение" label-for="block">
            <validation-provider #default="{ errors }" name="Строение">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="HomeIcon" />
                </b-input-group-prepend>

                <b-form-input
                  id="block"
                  v-model="form.block"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  placeholder="(Не указано)"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col sm="4">
          <b-form-group label="Квартира" label-for="apartment">
            <validation-provider
              #default="{ errors }"
              name="Квартира"
              rules="required|integer"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="HomeIcon" />
                </b-input-group-prepend>

                <b-form-input
                  id="apartment"
                  v-model="form.apartment"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder="(Не указано)"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group label="Почтовый индекс" label-for="post-index">
            <validation-provider
              #default="{ errors }"
              name="Почтовый индекс"
              rules="required|min:5"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="InboxIcon" />
                </b-input-group-prepend>

                <b-form-input
                  id="post-index"
                  v-model="form.post_index"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder="123456"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
            label="№ отделения «Новой почты»"
            label-for="post-office"
          >
            <validation-provider
              #default="{ errors }"
              name="№ отделения «Новой почты»"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="InboxIcon" />
                </b-input-group-prepend>

                <b-form-input
                  id="post-office"
                  v-model="form.post_office"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder="123456"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="saveSettings"
          >
            Сохранить изменения
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, min } from '@validations'
import {
  BButton,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupPrepend,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import WayupSelect from '@/components/WayupSelect.vue'
import { userStore } from '@/store'

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupPrepend,
    BOverlay,

    WayupSelect,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      min,

      form: {
        country_id: null,
        city_id: null,
        street: '',
        house: '',
        block: null,
        apartment: null,
        post_index: null,
        post_office: null,
      },
      formErrors: null,

      selectedCountry: null,
      selectedCity: null,
      citiesPending: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountries',
      cities: 'getCities',
    }),
    user() {
      return userStore.user
    },
  },
  async mounted() {
    this.isLoading = true

    await this.$store.dispatch('fetchCountries')
    await this.$store.dispatch('fetchCities', this.user.country_id)

    this.fillData()

    this.isLoading = false
  },
  methods: {
    fillData() {
      this.form.country_id = this.user.country_id
      this.form.city_id = this.user.city_id
      this.form.street = this.user.street
      this.form.house = this.user.house
      this.form.block = this.user.block
      this.form.apartment = this.user.apartment
      this.form.post_index = this.user.post_index
      this.form.post_office = this.user.post_office

      this.selectedCountry = this.countries.find(
        country => country.id === this.user.country_id,
      )

      this.selectedCity = this.cities.find(
        city => city.id === this.user.city_id,
      )
    },
    async onCountryChanged() {
      this.form.country_id = this.selectedCountry.id
      this.citiesPending = true
      await this.$store.dispatch('fetchCities', this.selectedCountry.id)
      this.selectedCity = null
      this.form.city_id = null
      this.citiesPending = false
    },
    onCityChanged() {
      this.form.city_id = this.selectedCity.id
    },
    async saveSettings() {
      const status = await this.$refs.profileLocation.validate()

      if (status) {
        this.isLoading = true

        await this.$store.dispatch('updateLocation', this.form)

        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss"></style>

<template>
  <b-card>
    <b-overlay rounded="sm" :show="isLoading" no-wrap />

    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-avatar size="80px" :src="form.avatar_url" rounded />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.avatarInput.$el.click()"
        >
          Выбрать фото
        </b-button>
        <b-form-file
          ref="avatarInput"
          v-model="form.avatar"
          accept=".jpg, .png"
          :hidden="true"
          plain
          @input="handleAvatarUpload"
        />
        <!--/ upload button -->

        <!-- reset -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
          @click="resetAvatar"
        >
          Сбросить
        </b-button>
        <!--/ reset -->
        <b-card-text>
          Доступные форматы: JPG, JPEG или PNG. Максимальный размер: 10 MB
        </b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <validation-observer ref="profileGeneral" tag="form" class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group label="Имя" label-for="first-name">
            <validation-provider
              #default="{ errors }"
              name="Имя"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="UserIcon" />
                </b-input-group-prepend>

                <b-form-input
                  id="first-name"
                  v-model="form.first_name"
                  placeholder="Иван"
                  :state="errors.length > 0 ? false : null"
                />
              </b-input-group>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group label="Фамилия" label-for="last-name">
            <validation-provider
              #default="{ errors }"
              name="Фамилия"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="UserIcon" />
                </b-input-group-prepend>

                <b-form-input
                  id="last-name"
                  v-model="form.last_name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Иванов"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group label="Email" label-for="email">
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="MailIcon" />
                </b-input-group-prepend>

                <b-form-input
                  id="email"
                  v-model="form.email"
                  type="email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="ivan.ivanov@email.com"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group label="Номер телефона" label-for="phone">
            <validation-provider
              #default="{ errors }"
              name="Номер телефона"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="PhoneIcon" />
                </b-input-group-prepend>

                <cleave
                  id="phone"
                  v-model="form.phone"
                  class="form-control"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  :raw="false"
                  :options="options.phone"
                  placeholder="1 234 567 8900"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group label="Дата рождения" label-for="birth-date-input">
            <validation-provider
              #default="{ errors }"
              name="Дата рождения"
              rules="required"
            >
              <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                <cleave
                  id="birth-date-input"
                  v-model="form.dob"
                  class="form-control"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  :raw="false"
                  :options="options.date"
                  placeholder="дд.мм.гггг"
                  autocomplete="off"
                  show-decade-nav
                  @blur="formatDate"
                />

                <b-input-group-append>
                  <b-form-datepicker
                    v-model="datepickerBirthday"
                    show-decade-nav
                    start-weekday="1"
                    hide-header
                    v-bind="labels"
                    :max="maxDate"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    button-only
                    :button-variant="
                      errors.length > 0 ? 'outline-danger' : 'outline-primary'
                    "
                    right
                    size="sm"
                    locale="ru-RU"
                    aria-controls="birth-date-input"
                    @context="onContext"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Telegram" label-for="telegram">
            <validation-provider
              #default="{ errors }"
              name="Telegram"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                prepend="@"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="telegram"
                  v-model="form.telegram"
                  :state="errors.length > 0 ? false : null"
                  placeholder="user"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Должность" label-for="position">
            <validation-provider
              #default="{ errors }"
              name="Должность"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="position"
                  v-model="form.position"
                  :state="errors.length > 0 ? false : null"
                  placeholder="(Не указано)"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Достижения" label-for="achievements">
            <validation-provider
              #default="{ errors }"
              name="Достижения"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-textarea
                  id="achievements"
                  v-model="form.achievements"
                  :state="errors.length > 0 ? false : null"
                  placeholder="(Не указано)"
                  rows="4"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="saveSettings"
          >
            Сохранить изменения
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BRow,
  BCol,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BFormDatepicker,
  BOverlay,
  BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, email } from '@validations'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import { showError } from '@/helpers/notifications'
import { userStore } from '@/store'

export default {
  components: {
    BButton,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormDatepicker,
    BOverlay,
    BAvatar,

    ValidationProvider,
    ValidationObserver,

    Cleave,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      profileFile: null,

      form: {
        first_name: '',
        last_name: '',
        dob: null,
        phone: '',
        telegram: '',
        avatar: null,
        avatar_url: null,
        email: '',
      },

      // validation rules
      required,
      email,

      datepickerBirthday: '',
      labels: {
        labelPrevYear: 'Предыдущий год',
        labelPrevMonth: 'Предыдущий месяц',
        labelPrevDecade: 'Предыдущее десятилетие',
        labelCurrentMonth: 'Текущий месяц',
        labelNextMonth: 'Следующий месяц',
        labelNextYear: 'Следующий год',
        labelNextDecade: 'Следующее десятилетие',
        labelToday: 'Сегодня',
        labelSelected: 'Выбранная дата',
        labelNoDateSelected: 'Нет выбранной даты',
        labelCalendar: 'Календарь',
        labelNav: 'Навигация',
        labelHelp: '',
      },
      options: {
        date: {
          date: true,
          delimiter: '.',
          datePattern: ['d', 'm', 'Y'],
        },
        phone: {
          phone: true,
          phoneRegionCode: 'RU',
        },
      },
      isLoading: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    maxDate() {
      return new Date().toISOString().slice(0, 10)
    },
    user() {
      return userStore.user
    },
  },
  mounted() {
    this.fillData()
  },
  methods: {
    fillData() {
      this.form.first_name = this.user.first_name
      this.form.last_name = this.user.last_name
      this.form.email = this.user.email
      this.form.phone = this.user.phone
      this.form.dob = this.user.dob
      this.formatDate(this.user.dob)
      this.form.telegram = this.user.telegram
      this.form.position = this.user.position
      this.form.achievements = this.user.achievements?.join('\n')
      this.form.avatar_url = this.user.avatar_url
    },
    handleAvatarUpload() {
      const uploadedFile = this.$refs.avatarInput.files[0]
      if (!uploadedFile) return
      if (uploadedFile.size > 10240 * 1024) {
        this.$refs.avatarInput.reset()
        showError('Размер файла не должен превышать 10 MB')
        return
      }
      this.form.avatar = uploadedFile
      this.setAvatarPreview(uploadedFile)
    },
    setAvatarPreview(file) {
      if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
        const reader = new FileReader()
        reader.addEventListener(
          'load',
          () => {
            this.form.avatar_url = reader.result
          },
          false,
        )
        reader.readAsDataURL(file)
      }
    },
    resetAvatar() {
      this.form.avatar = null
      this.form.avatar_url = this.user.avatar_url
    },
    onContext(ctx) {
      this.form.dob =
        ctx.selectedFormatted === 'Нет выбранной даты'
          ? ''
          : ctx.selectedFormatted
      this.datepickerBirthday = ctx.selectedYMD
    },
    formatDate(date) {
      this.datepickerBirthday = date
        .split('.')
        .reverse()
        .join('-')
    },
    async saveSettings() {
      const status = await this.$refs.profileGeneral.validate()

      if (status) {
        this.isLoading = true

        const data = { ...this.form }

        data.achievements = this.form.achievements.split('\n')

        await this.$store.dispatch('updateGeneralSettings', data)

        this.isLoading = false
      }
    },
  },
  setup() {
    localize('ru')
  },
}
</script>

<style lang="scss">
@import '@core/scss/base/bootstrap-extended/include';
@import '@core/scss/base/components/variables-dark';

.dark-layout {
  .b-overlay {
    .bg-light {
      background-color: $theme-dark-body-bg !important;
    }
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-overlay',{attrs:{"rounded":"sm","show":_vm.isLoading,"no-wrap":""}}),_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',[_c('b-link',[_c('b-avatar',{attrs:{"size":"80px","src":_vm.form.avatar_url,"rounded":""}})],1)],1),_c('b-media-body',{staticClass:"mt-75 ml-75"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75 mr-75",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$refs.avatarInput.$el.click()}}},[_vm._v(" Выбрать фото ")]),_c('b-form-file',{ref:"avatarInput",attrs:{"accept":".jpg, .png","hidden":true,"plain":""},on:{"input":_vm.handleAvatarUpload},model:{value:(_vm.form.avatar),callback:function ($$v) {_vm.$set(_vm.form, "avatar", $$v)},expression:"form.avatar"}}),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75 mr-75",attrs:{"variant":"outline-secondary","size":"sm"},on:{"click":_vm.resetAvatar}},[_vm._v(" Сбросить ")]),_c('b-card-text',[_vm._v(" Доступные форматы: JPG, JPEG или PNG. Максимальный размер: 10 MB ")])],1)],1),_c('validation-observer',{ref:"profileGeneral",staticClass:"mt-2",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Имя","label-for":"first-name"}},[_c('validation-provider',{attrs:{"name":"Имя","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":"first-name","placeholder":"Иван","state":errors.length > 0 ? false : null},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Фамилия","label-for":"last-name"}},[_c('validation-provider',{attrs:{"name":"Фамилия","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":"last-name","state":errors.length > 0 ? false : null,"placeholder":"Иванов"},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"MailIcon"}})],1),_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false : null,"placeholder":"ivan.ivanov@email.com"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Номер телефона","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Номер телефона","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"PhoneIcon"}})],1),_c('cleave',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"id":"phone","raw":false,"options":_vm.options.phone,"placeholder":"1 234 567 8900"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Дата рождения","label-for":"birth-date-input"}},[_c('validation-provider',{attrs:{"name":"Дата рождения","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{class:errors.length > 0 ? 'is-invalid' : null},[_c('cleave',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"id":"birth-date-input","raw":false,"options":_vm.options.date,"placeholder":"дд.мм.гггг","autocomplete":"off","show-decade-nav":""},on:{"blur":_vm.formatDate},model:{value:(_vm.form.dob),callback:function ($$v) {_vm.$set(_vm.form, "dob", $$v)},expression:"form.dob"}}),_c('b-input-group-append',[_c('b-form-datepicker',_vm._b({attrs:{"show-decade-nav":"","start-weekday":"1","hide-header":"","max":_vm.maxDate,"date-format-options":{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  },"button-only":"","button-variant":errors.length > 0 ? 'outline-danger' : 'outline-primary',"right":"","size":"sm","locale":"ru-RU","aria-controls":"birth-date-input"},on:{"context":_vm.onContext},model:{value:(_vm.datepickerBirthday),callback:function ($$v) {_vm.datepickerBirthday=$$v},expression:"datepickerBirthday"}},'b-form-datepicker',_vm.labels,false))],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Telegram","label-for":"telegram"}},[_c('validation-provider',{attrs:{"name":"Telegram","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"prepend":"@"}},[_c('b-form-input',{attrs:{"id":"telegram","state":errors.length > 0 ? false : null,"placeholder":"user"},model:{value:(_vm.form.telegram),callback:function ($$v) {_vm.$set(_vm.form, "telegram", $$v)},expression:"form.telegram"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Должность","label-for":"position"}},[_c('validation-provider',{attrs:{"name":"Должность","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{attrs:{"id":"position","state":errors.length > 0 ? false : null,"placeholder":"(Не указано)"},model:{value:(_vm.form.position),callback:function ($$v) {_vm.$set(_vm.form, "position", $$v)},expression:"form.position"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Достижения","label-for":"achievements"}},[_c('validation-provider',{attrs:{"name":"Достижения","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-textarea',{attrs:{"id":"achievements","state":errors.length > 0 ? false : null,"placeholder":"(Не указано)","rows":"4"},model:{value:(_vm.form.achievements),callback:function ($$v) {_vm.$set(_vm.form, "achievements", $$v)},expression:"form.achievements"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"variant":"primary"},on:{"click":_vm.saveSettings}},[_vm._v(" Сохранить изменения ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
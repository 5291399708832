<template>
  <b-card>
    <b-overlay
      rounded="sm"
      :show="isLoading"
      no-wrap
    />

    <!-- form -->
    <validation-observer
      ref="profilePassword"
      tag="form"
    >
      <b-row>
        <!-- old password -->
        <b-col md="6">
          <b-form-group label="Старый пароль" label-for="old-password">
            <validation-provider
              #default="{ errors }"
              name="Старый пароль"
              vid="password"
              rules="required|min:8"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="LockIcon" />
                </b-input-group-prepend>

                <b-form-input
                  id="old-password"
                  v-model="oldPassword"
                  :type="passwordFieldTypeOld"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Введите старый пароль"
                />

                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconOld"
                    class="cursor-pointer"
                    @click="togglePasswordOld"
                  />
                </b-input-group-append>
              </b-input-group>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--/ old password -->
      </b-row>

      <b-row>
        <!-- new password -->
        <b-col md="6">
          <b-form-group label="Новый пароль" label-for="new-password">
            <validation-provider
              #default="{ errors }"
              name="Новый пароль"
              vid="new-password"
              rules="required|min:8"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="LockIcon" />
                </b-input-group-prepend>

                <b-form-input
                  id="new-password"
                  v-model="newPassword"
                  :type="passwordFieldTypeNew"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Введите новый пароль"
                />

                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconNew"
                    class="cursor-pointer"
                    @click="togglePasswordNew"
                  />
                </b-input-group-append>
              </b-input-group>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="6">
          <b-form-group label="Подтверждение пароля" label-for="new-password-confirmation">
            <validation-provider
              #default="{ errors }"
              name="Подтверждение пароля"
              vid="new-password-confirmation"
              rules="required|min:8|confirmed:new-password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="LockIcon" />
                </b-input-group-prepend>

                <b-form-input
                  id="new-password-confirmation"
                  v-model="passwordConfirmation"
                  :type="passwordFieldTypeRetype"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Введите новый пароль ещё раз"
                />

                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconRetype"
                    class="cursor-pointer"
                    @click="togglePasswordRetype"
                  />
                </b-input-group-append>
              </b-input-group>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="saveChanges"
          >
            Сохранить изменения
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, min } from '@validations'
import Ripple from 'vue-ripple-directive'
import { showError } from '@/helpers/notifications'

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      min,

      oldPassword: '',
      newPassword: '',
      passwordConfirmation: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      isLoading: false,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    async saveChanges() {
      const status = await this.$refs.profilePassword.validate()

      if (status) {
        if (this.oldPassword === this.newPassword) {
          showError('Старый пароль не должен совпадать с новым')
          return
        }

        this.isLoading = true

        const data = {
          old_password: this.oldPassword,
          password: this.newPassword,
          password_confirmation: this.passwordConfirmation,
        }
        await this.$store.dispatch('updatePassword', data)

        this.isLoading = false
      }
    },
  },
}
</script>

<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Основные настройки</span>
      </template>

      <user-setting-general />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Изменить пароль</span>
      </template>

      <user-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- info -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="MapPinIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Местоположение</span>
      </template>

      <user-setting-information />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import UserSettingGeneral from '@/components/userSettings/UserSettingGeneral.vue'
import UserSettingPassword from '@/components/userSettings/UserSettingPassword.vue'
import UserSettingInformation from '@/components/userSettings/UserSettingLocation.vue'

export default {
  components: {
    BTabs,
    BTab,
    UserSettingGeneral,
    UserSettingPassword,
    UserSettingInformation,
  },
}
</script>
